import { DropdownItem, DropdownMenu, DropdownToggle, UncontrolledDropdown } from 'reactstrap';
import React from 'react';

interface Props {
    isLoggedIn: boolean;
    user?: any;
    logout: () => void;
}

const UserProfileDropdown = ({ isLoggedIn, user, logout }: Props) => {

    if (isLoggedIn) {
        return (
            <UncontrolledDropdown nav inNavbar>
                <DropdownToggle nav caret>
                    {
                        user?.username ? user.username : "Error"
                    }
                </DropdownToggle>
                <DropdownMenu right>
                    <DropdownItem onClick={logout}>
                        Logout
                    </DropdownItem>
                </DropdownMenu>
            </UncontrolledDropdown>
        );
    } else {
        return null;
    }
};

export default UserProfileDropdown;