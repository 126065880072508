import DashboardPage from './DashboardPage';
import React, {useState} from 'react';
import {Authenticator, Greetings, SignUp} from 'aws-amplify-react';
import {setUser} from '../services/auth';
import {Auth} from 'aws-amplify';
import {navigate} from 'gatsby-link';


const authTheme = {
    button: {
        backgroundColor: "rgb(12, 158, 145)"
    }
};

// eslint-disable-next-line @typescript-eslint/no-empty-interface
interface Props {

}


export const Login = ({}: Props) => {
    const [isLoggedIn, setIsLoggedIn] = useState(false);

    const onStateChange = (authState: string) => {
        Auth.currentAuthenticatedUser({
            bypassCache: false,  // Optional, by default is false. If set to true, this call will send a request to Cognito to get the latest user data
        }).then(user => {
                if (authState != 'signedIn') {
                    return null;
                }
                return user;
            },
        ).then(user => {
                if (user) {
                    setIsLoggedIn(true);
                    setUser(user);
                } else {
                    setIsLoggedIn(false);
                }
            },
        ).catch((err) => {
            setUser(null);
            setIsLoggedIn(false);
        });
    };

    if (isLoggedIn) {
        // navigate('/app/control');
        navigate('/app/downloads');
        return null;
    } else {
        return (
            <DashboardPage seoTitle={'Maia'} sideMenuItems={[]}>
                <h1>Welcome to Maia</h1>
                <p>Please log in.</p>
                <Authenticator theme={authTheme} onStateChange={onStateChange} hide={[Greetings, SignUp]}>
                </Authenticator>
            </DashboardPage>
        );
    }
};

