import { DropdownItem, DropdownMenu, DropdownToggle, Nav, NavItem, UncontrolledDropdown } from 'reactstrap';
import { Link } from 'gatsby';
import React from 'react';

interface Props {
    isLoggedIn: boolean;
}


const LoggedInAwareNavbar = ({ isLoggedIn}: Props) => {
    if (isLoggedIn) {
        return (
            <Nav className="mr-auto" navbar>
                    {/*<NavItem>*/}
                    {/*    <Link to="/app/control" activeClassName="active" className="nav-link">Control Plane</Link>*/}
                    {/*</NavItem>*/}
                    {/*<UncontrolledDropdown nav inNavbar>*/}
                    {/*    <DropdownToggle nav caret>*/}
                    {/*        {'Documentation'}*/}
                    {/*    </DropdownToggle>*/}
                    {/*    <DropdownMenu right>*/}
                    {/*        <DropdownItem>*/}
                    {/*            <Link to="/app/docs/user" className="nav-link">User</Link>*/}
                    {/*        </DropdownItem>*/}
                    {/*        <DropdownItem>*/}
                    {/*            <Link to="/app/docs/developer" className="nav-link">Developer</Link>*/}
                    {/*        </DropdownItem>*/}
                    {/*        <DropdownItem divider />*/}
                    {/*        <DropdownItem>*/}
                    {/*            <Link to="/app/docs/internal" className="nav-link">Internal Documentation</Link>*/}
                    {/*        </DropdownItem>*/}
                    {/*        <DropdownItem divider />*/}
                    {/*        <DropdownItem>*/}
                    {/*            Engineering*/}
                    {/*        </DropdownItem>*/}
                    {/*        <DropdownItem>*/}
                    {/*            Audit / Controls*/}
                    {/*        </DropdownItem>*/}
                    {/*    </DropdownMenu>*/}
                    {/*</UncontrolledDropdown>*/}
                    <NavItem>
                        <Link to="/app/downloads" activeClassName="active" className="nav-link">Downloads</Link>
                    </NavItem>
            </Nav>
        );
    } else {
        return (
            <Nav className="mr-auto" navbar>
            </Nav>
            );
    }
};

export default LoggedInAwareNavbar;