import { Auth } from 'aws-amplify';
import { navigate } from 'gatsby-link';

const isBrowser = typeof window !== `undefined`;

export const setUser = (user: any) => (window.localStorage.gatsbyUser = JSON.stringify(user));

const getUser = (): any => {
    if (window.localStorage.gatsbyUser) {
        const user = JSON.parse(window.localStorage.gatsbyUser);
        return user ? user : {};
    }
    return {};
};

export const isLoggedIn = (): boolean => {
    if (!isBrowser) return false;

    const user = getUser();
    if (user) {
        return !!user.username;
    } else {
        return false;
    }
};

export const getCurrentUser = (): any => {
    return isBrowser && getUser();
};

export const logout = (): void => {
    Auth.signOut({ global: true })
        .then(() => {
            if (!isBrowser) return;
            setUser({});
            navigate('/');
        })
        .catch((err: any) => console.log(err));
};